<template>
  <div class="shipInfo-cell" @click="to(data.to)">
    <van-row>
      <van-col offset="1" span="23" class="text van-ellipsis" :style="{marginTop:'-20px', textAlign:'left'}">
        {{data.no }}.{{data.vesselName }}
      </van-col>
    </van-row>
    <van-row :style="{marginTop:'0px'}">
      <van-col offset="2" span="13" class="text van-ellipsis info">
        {{ data.dwt }} {{ data.charterer }}
      </van-col>
      <van-col offset="4" span="4" :style="{color:'#6390E3', fontSize:'13px',marginTop:'-8px'}">
        {{ data.detail }}
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "index",
  methods: {
    to(to) {
      this.$router.push(to);
    }
  },
  props: {
    data: Object
  }
}
</script>

<style scoped>
.shipInfo-cell {
  background: #ffffff;
  margin-top: 3px;
  border-radius: 2px;
  padding-top: 4px;
}

.shipInfo-cell .circle {
  display: inline-block;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  padding: 5px;
  background: #3B71BE;
  color: white;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
  transform: translate(0%, 0%);
}

.shipInfo-cell .circle:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
  height: 0;
}

.shipInfo-cell .circle span {
  display: inline-block;
  vertical-align: middle;
}

.shipInfo-cell .text {
  text-align: left;
}

.shipInfo-cell .title {
  font-weight: 400;
  font-size: 18px;
  color: #111111;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.shipInfo-cell .info {
  font-weight: 400;
  font-size: 14px;
  color: #827E7E;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.shipInfo-cell .user {
  font-weight: 400;
  font-size: 14px;
  color: #318EF5;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.shipInfo-cell .tag {
  font-weight: 700;
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

</style>

<style>
.van-list .shipInfo-cell .van-row:first-child {
  padding-top: 20px;
}
</style>