<template>
  <div class="ship-info-list">
    <!-- 搜索框-->
    <van-row style="background-color: white">
      <van-col span="5" style="margin-top: 20px;padding-left: 10px">
        <i @click="showTypeFzr()" :class="`el-icon-collection-tag`"
           :style="{fontSize: '13px'}">全部</i>
        <i @click="showTypeFzr()" :class="`el-icon-arrow-down`"
           :style="{fontSize: '15px'}"></i>
      </van-col>
      <van-search v-model="vesselName" placeholder="请输入搜索关键词" show-action
                  @cancel="onCancel()" @search="onRefresh()"/>
    </van-row>
    <van-row>
      <van-col span="14" :style="{fontSize:'15px'}">当前查询数据条数：{{ listRow }}条</van-col>
    </van-row>
    <!-- 列表-->
    <div ref="fresh" :style="{height:freshHeight +'px','overflow-y':'scroll','box-sizing': 'border-box'}">
      <div v-if="list.length<=0">
        <van-empty image="error" description="没有数据"/>
      </div>
      <div v-else>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
              v-model="loading"
              loading-text="加载中"
              :finished="finished"
              finished-text="没有更多了"
              @load="nextPage"
              style="margin-bottom: 45px"
          >
            <Ship-info-cell v-for="item in list" v-bind:key="item.id" :data="item"/>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <ship-info-select :data="this.columns" ref="typeFzr" @zcr="zcr(arguments)"></ship-info-select>
    <Add v-if="isAdd" to="/BulkCrmsShipInfoDetailAdd/" :param=param></Add>
    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="5"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import ShipInfoCell from '@/components/ShipInfoCell/index'
import Add from '@/components/Add';
import ShipInfoSelect from "@/components/ShipInfoSelect/index";
import {Loading} from 'vant';


export default {
  name: "BulkCrmsShipInfoList",
  components: {CrmTabBar, ShipInfoCell, Add, ShipInfoSelect, Loading},
  data() {
    return {
      showPopup: false,
      param: '',
      columns: [
        {
          values: '',
          pid: '16034',
          text: '刘小恺',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
        },
        {
          values: '',
          pid: '19244',
          text: '董阔',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
        },
        {
          values: '',
          pid: '18252',
          text: '纪红昊',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
        },
        {
          values: '',
          pid: '16050',
          text: '冷琳',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
        },
        {
          values: '',
          pid: '18030',
          text: '李佳宇',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
        },
        {
          values: '',
          pid: '22058',
          text: '路志国',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
        },
        {
          values: '',
          pid: '16086',
          text: '王帅',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
        },
        {
          values: '',
          pid: '04008',
          text: '张鸿凯',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
        }
      ],
      colors: '',
      list: [],
      listRow: '',
      vesselName: '',
      //列表高度
      freshHeight: 0,
      //下拉标识
      isLoading: false,
      number1: null,
      number2: null,
      pickZcr: '',
      currentPage: 1, // 当前页码
      pageSize: 20, // 每页个数
      loading: false, // 加载下一页
      finished: false, // 是否加载完了
      refreshing: false,
      isAdd: false
    }
  },
  watch: {
    //监听公司名头
    'vesselName': function () {
      this.list = [];
      this.onRefresh();
    },
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - this.$refs.fresh.getBoundingClientRect().top - 50
    if(this.$route.params.id === 'no'){
      this.$message.warning("该客户还没有对应船舶数据！");
    }else{
      this.onRefresh();
    }
    this.param = this.$route.params.id
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.currentPage = 1
      this.list = []
      this.finished = false
      this.refreshing = true
      this.loading = true
      this.getData()
    },
    // 上拉加载
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        this.finished = true;
      }, 1000);
    },
    showTypeFzr() {
      this.$refs.typeFzr.showPopup = true
      this.$refs.typeFzr.pickZcr = 'null'
      this.showPopup = true
    },
    zcr(msg) {
      // data为子页面的传过来的数据
      console.log(msg[0], msg[1], msg[2])
      this.number1 = msg[0] != 'null' ? msg[0] : 0
      this.number2 = msg[1] != 'null' ? msg[1] : 0
      this.pickZcr = msg[2] != 'null' ? msg[2] : ''
      this.onRefresh()
    },
    onCancel() {
      this.vesselName = ''
      this.pickZcr = ''
      this.number1 = 0;
      this.number2 = 0;
      this.onRefresh();
    },
    getData() {
      this.loading = true
      this.$ajax.post('/api/ajax/bulkCrmShipinfo/getBulkCrmShipinfotwoList.json',
          {
            number1: this.number1,
            number2: this.number2,
            charterer: this.pickZcr,
            vesselName: this.vesselName,
            vesselId: '',
            shipInformationid: this.$route.params.id,
            current: this.currentPage,
            size: this.pageSize,
          }).then(res => {
        this.loading = false
        if (res.status == 200) {
          if(res.data.length==0){
            this.$message.warning("还没有对应船舶数据！");
            this.listRow = 0
          }else{
            if(res.data[0].createdPeople == this.$store.getters.userId){
              this.isAdd = true
            }
            // 如果下拉刷新，那么数据清空重新获取第一页
            if (this.refreshing) {
              this.list = []
              this.refreshing = false
            }
            console.log(res.data)
            res.data.forEach(item => {
              this.list.push(item)
            })
            this.listRow = this.list.length
            // 如果数据加载完毕，finished 标志位置为 true，滑到页面底部则不会再触发 nextPage() 方法了。currentPage 重置为 1
            if (res.data.length < this.pageSize - 1) {
              this.finished = true
            } else {
              this.finished = false
            }
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].no = i + 1
              this.list[i].detail = '查看详情'
              this.list[i].to = '/BulkCrmsShipInfoDetailList/' + this.list[i].vesselId
            }
          }
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('查询失败！');
      })
    },
    // 加载下一页
    nextPage() {
      setTimeout(() => {
        if (!this.finished) {
          this.loading = false
          this.currentPage += 1
          this.getData()
        }
      }, 500)
    },
  }
}
</script>

<style scoped>

</style>
