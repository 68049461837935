<template>
  <div class="index">
    <van-popup
        v-model="showPopup"
        position="top"
        :style="{ height: '50%' }"
    >
      <div>
        <van-row span="24" class="pickTitle">
          <van-col offset="0" span="4">载重吨</van-col>
        </van-row>
        <van-row span="24" class="pickArea">
          <van-col span="12">
            <van-field
                v-model="number1"
                label="吨数始"
                placeholder="输入数字"
                type="number"
            />
          </van-col>
          <van-col span="12">
            <van-field
                v-model="number2"
                label="吨数终"
                placeholder="输入数字"
                type="number"
            />
          </van-col>
        </van-row>
        <van-row span="24" class="pickTitle">
          <van-col offset="0" span="4">租船人</van-col>
        </van-row>
        <van-row span="24" class="pickArea">
          <van-col offset="1" span="7" class="pickArea" v-for="(item, index) in data"
                   v-bind:key="item.pid">
            <div class="pickDiv"
                 :style="{color: item.use ? item.color : 'black', background: item.use ? item.backColor: '#F3F3F3'}"
                 @click="selectZcr(item,index)">
              {{ item.text }}
            </div>
          </van-col>
        </van-row>
        <van-row span="24" class="pickButton">
          <van-col span="12">
            <van-button size="large" type="default" @click="onClear()">清空</van-button>
          </van-col>
          <van-col span="12">
            <van-button class="pickBtn" size="large" type="default" @click="confirm()">确定
            </van-button>
          </van-col>
        </van-row>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import {Row, Col, Cell} from 'vant'

Vue.use(Row).use(Col).use(Cell)
export default {
  name: "index",
  props: {
    data: {type: Array},
    btn: {type: Boolean}
  },
  data() {
    return {
      showPopup: false,
      pickZcr: 'null',
      number1: 'null',
      number2: 'null',
      baseInfo: [
        {
          index: 1,
          titel: '船型',
          label: '请选择',
          showPicker: false,
          columns: [
            '刘小恺',
            '董阔',
            '纪红昊',
            '冷琳',
            '李佳宇',
            '路志国'
          ]
        }
      ]
    };
  },

  methods: {
    /*清空所选内容*/
    onClear() {
      for (let i = 0; i < this.data.length; i++) {
        this.data[i].use = false;
      }
      this.number1 = 'null',
      this.number2 = 'null',
      this.pickZcr = 'null'
    },
    confirm() {
      this.showPopup = false
      this.$emit("zcr", this.number1, this.number2, this.pickZcr);
      this.onClear()
    },
    selectZcr(item, index) {
      this.pickZcr = '';
      console.log(item)
      for (let i = 0; i < this.data.length; i++) {
        this.data[i].use = false;
      }
      item.use = true;
      this.pickZcr = item.text
    },
  },

  watch: {}
}
</script>

<style scoped>
.index .pickTitle {
  margin-top: 20px;
  font-weight: bolder;
  font-family: 华文宋体
}

.index .pickArea {
  margin-top: 10px;
  font-size: 10px
}

.index .pickButton{

  margin-top: 50px;
}

.index .pickDiv {
  width: 100px;
  height: 36px;
  background-color: #F8F8F8;
  text-align: center;
  line-height: 36px;
  vertical-align: middle
}

.index .pickBtn {
  background-color: #3AADFF
}

</style>